import FormContext from "@component/core/Context/Form/FormContext";
import { Combobox, Text as FluentText, Option } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import { joinPaths } from "@lib/form/path";
import unflatten from "@lib/form/unflatten";
import React from "react";
import { useContext, useEffect, useState } from "react";
const tag = "@component/view/StringArray";
export function StringArray({
  item,
  titleWidth,
  path = ""
}) {
  const {
    form,
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const inputId = useId("input");
  const p = joinPaths(path, item.attrib);
  const pArray = item.selectFromArray;
  const value = getByPath(p) || [];
  const onSelect = (ev, data) => {
    updateByPath(p, data.selectedOptions);
  };
  const values = unflatten(form, pArray).map(i => i[item.selectFromAtribute]);
  if (values && value && value.length > 0) {
    let nv = [];
    let updateValue = false;
    for (let i = 0; i < value.length; i++) {
      if (values.indexOf(value[i]) < 0) {
        updateValue = true;
      } else {
        nv.push(value[i]);
      }
    }
    if (updateValue) {
      updateByPath(p, nv);
    }
  }
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <Combobox value={value.join(", ")} multiselect style={{
      flex: "1 1 auto"
    }} onOptionSelect={onSelect} selectedOptions={value}>
                {values.map((v, index) => <Option key={`${index};${v}`}>
                        {v}
                    </Option>)}
            </Combobox>
        </div>;
}
export default StringArray;