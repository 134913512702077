import FormContext from "@component/core/Context/Form/FormContext";
import { Input, Text as FluentText } from "@fluentui/react-components";
import { NumberSymbolSquareRegular } from "@fluentui/react-icons";
import { joinPaths } from "@lib/form/path";
import { useContext } from "react";
const tag = "@component/view/Number";
export function Number({
  item,
  titleWidth,
  path = "",
  navItem,
  itemData,
  list
}) {
  const {
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const p = joinPaths(path, item.attrib);
  const num = getByPath(p);
  const onChange = (ev, data) => {
    let v = data.value;
    if (typeof data.value === "string") {
      v = parseFloat(v);
    }
    if (isNaN(v)) {
      v = null;
    }
    updateByPath(p, v);
  };
  if (item.autoNumbering && list instanceof Array && typeof itemData === "object" && !(itemData instanceof Array) && !!itemData && typeof itemData._index === "number" && itemData._index + 1 !== num) {
    updateByPath(p, itemData._index + 1);
  }
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <Input style={{
      flex: "1 1 auto"
    }} type="number" contentAfter={<NumberSymbolSquareRegular />} disabled={!!item.readOnly || !!navItem.data.readOnly} value={typeof num === "number" ? String(num) : ""} onChange={onChange} />

        </div>;
}
export default Number;