import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText, Textarea } from "@fluentui/react-components";
import { joinPaths } from "@lib/form/path";
import { useContext } from "react";
const tag = "@component/view/Text";
const defaultTitleWidth = 250;
export function TextArea({
  item,
  titleWidth,
  path = "",
  navItem
}) {
  const {
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const p = joinPaths(path, item.attrib);
  const text = getByPath(p);
  const style: Record<string, any> = {
    flex: "1 1 auto"
  };
  const textarea: Record<string, any> = {};
  if (typeof item.minHeight === "number" && item.minHeight >= 0 || typeof item.minHeight === "string" && item.minHeight) {
    textarea.style = {
      maxHeight: "unset",
      minHeight: item.minHeight
    };
  }
  const onChange = (ev, data) => {
    updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : defaultTitleWidth,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <Textarea style={style} textarea={textarea} resize="vertical" value={text} onChange={onChange} disabled={!!item.readOnly || !!navItem.data.readOnly} />

        </div>;
}
export default TextArea;