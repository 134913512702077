import { v4 as uuidv4 } from 'uuid';
import { joinPaths } from '@lib/form/path';
export const flatten = (data, path = "") => {
  if (typeof data === "object" && data instanceof Array && data.length === 0) {
    return {};
  }
  if (typeof data !== "object" || data === null) {
    return {
      [path]: data
    };
  }
  const uids = [];
  const genUniqueUID = () => {
    let uid = uuidv4();
    if (uids.indexOf(uid) >= 0) {
      uid = uuidv4();
    }
    uids.push(uid);
    return uid;
  };
  let buff = data instanceof Array ? [{
    path: "",
    value: data
  }] : Object.keys(data).filter(key => !key.startsWith("_")).map(key => ({
    path: key,
    value: data[key]
  }));
  const obj = {};
  while (buff.length > 0) {
    const buffItem = buff.shift();
    if (typeof buffItem.value !== "object" || buffItem.value === null) {
      obj[`${path ? path + "." : ""}${buffItem.path}`] = buffItem.value;
    } else if (buffItem.value instanceof Array) {
      console.log(buffItem.value);
      buff = buffItem.value.map((value, index, arr) => {
        let k = "";
        let i = index;
        if (typeof value === "object" && !(value instanceof Array) && value && value._index) {
          i = value._index;
        }
        if (typeof value === "object" && !(value instanceof Array) && value && value._key) {
          k = value._key;
          uids.push(k);
        } else {
          k = genUniqueUID();
        }
        return {
          path: joinPaths(buffItem.path, k),
          value: typeof value === "object" && !(value instanceof Array) && !!value ? {
            ...value,
            _index: i
          } : value
        };
      }).concat(buff);
    } else {
      buff = Object.keys(buffItem.value).filter(key => !key.startsWith("_") || key === "_index").map(key => ({
        path: `${buffItem.path}.${key}`,
        value: buffItem.value[key]
      })).concat(buff);
    }
  }
  return obj;
};
export default flatten;