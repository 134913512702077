// TODO use normalizePath
export const joinPaths = (path1: string, path2: string) => {
  let p = path1 || "";
  p += path1 && path2 ? "." : "";
  p += path2 || "";
  return p;
};

// TODO
// + use across the system
export const normalizePath = (path: string) => {
  return path;
};