import { NavigationContextStateHistoryItem, NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import Form from "@view/Form";
import NavigationContext from "@core/Context/Navigation/NavigationContext";
import React, { useContext } from "react";
import FormDefinitionContext from "./Context/FormDefinition/FormDefinitionContext";
function View({
  item
}: {
  item: NavigationContextStateHistoryItem;
}) {
  switch (item.key) {
    case NavigationContextStateHistoryItemKey.FORM:
      return <Form key={item.uuid} navItem={item} />;
    default:
      return <Form key={item.uuid} navItem={item} />;
  }
}
export function Router() {
  const {
    navigation
  } = useContext(NavigationContext);
  const {
    formDefinition
  } = useContext(FormDefinitionContext);
  return <React.Fragment>
            <div style={{
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "stretch",
      overflow: "auto"
    }}>
                <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        overflow: "auto",
        flex: "1 1 auto",
        backgroundColor: "#DDDDDD"
      }}>
                    {formDefinition && formDefinition._loaded && navigation.history.map(item => <View item={item} key={item.uuid} />)}
                </div>
            </div>
        </React.Fragment>;
}
export default Router;