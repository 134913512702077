import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText } from "@fluentui/react-components";
import { DatePicker, defaultDatePickerStrings } from "@fluentui/react-datepicker-compat";
import type { CalendarStrings } from "@fluentui/react-datepicker-compat";
import { joinPaths, normalizePath } from "@lib/form/path";
import moment from "moment-timezone";
import { useContext, useState } from "react";
const tag = "@component/view/Text";
const localizedStrings: CalendarStrings = {
  ...defaultDatePickerStrings,
  days: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
  shortDays: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
  months: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
  shortMonths: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čvn", "Čvc", "Srp", "Zář", "Říj", "Lis", "Pro"],
  goToToday: "Přejít na dnešek"
};
const formatDate = (dt: Date) => {
  return moment(dt).format("D. M. YYYY");
};
export function Text({
  item,
  titleWidth,
  path = "",
  navItem,
  itemData
}) {
  const {
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const p = joinPaths(path, item.attrib);
  const value = getByPath(p);
  if (typeof item.computeValue === "object" && item.computeValue && !(item.computeValue instanceof Array) && item.computeValue.dtAttrib) {
    let dtP = itemData ? joinPaths(itemData._path, item.computeValue.dtAttrib) : normalizePath(item.computeValue.dtAttrib);
    const dt = getByPath(dtP);
    let v = moment(dt).tz("Europe/Prague");
    if (item.computeValue.offsetUnit && item.computeValue.offsetAttrib) {
      let oP = itemData ? joinPaths(itemData._path, item.computeValue.offsetAttrib) : normalizePath(item.computeValue.offsetAttrib);
      const offset = getByPath(oP) || 0;
      v.add(offset, item.computeValue.offsetUnit);
    }
    if (v.diff(moment(value)) !== 0) {
      updateByPath(p, v.utc().toISOString());
    }
  }
  const onChange = dt => {
    updateByPath(p, moment(dt).utc().toISOString());
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <DatePicker strings={localizedStrings} firstDayOfWeek={1} style={{
      flex: "1 1 auto"
    }} formatDate={formatDate} value={moment(value).tz("Europe/Prague").toDate()} onSelectDate={onChange} disabled={!!item.readOnly || !!navItem.data.readOnly} />

        </div>;
}

/*
    "computeValue": {
        "dtAttrib": "pocatekZarucniDoby",
        "offsetAttrib": "delkaZaruky",
        "offsetUnit": "month"
    }
*/

export default Text;