import FormContext from "@component/core/Context/Form/FormContext";
import { Input, Text as FluentText } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import { joinPaths } from "@lib/form/path";
import { useContext } from "react";
const tag = "@component/view/Text";
const defaultTitleWidth = 250;
export function Text({
  item,
  titleWidth,
  path = "",
  navItem
}) {
  const {
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const p = joinPaths(path, item.attrib);
  const pTitle = joinPaths(path, item.titleAttrib);
  const text = getByPath(p);
  const title = item.titleAttrib ? getByPath(pTitle) : "";
  const onChangeText = (ev, data) => {
    updateByPath(p, data.value);
  };
  const onChangeTitle = (ev, data) => {
    updateByPath(pTitle, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : defaultTitleWidth,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            {!!item.titleAttrib && <Input style={{
      width: typeof titleWidth === "number" ? titleWidth - 10 : defaultTitleWidth - 10,
      marginRight: 10,
      flexShrink: 1
    }} contentAfter={<TextFieldRegular />} value={title} disabled={!!item.readOnly || !item.editTitle || !!navItem.data.readOnly} placeholder={item.titlePlaceholder || ""} onChange={onChangeTitle} />}
            <Input style={{
      flex: "1 1 auto"
    }} contentAfter={<TextFieldRegular />} value={text} placeholder={item.placeholder || ""} disabled={!!item.readOnly || !!navItem.data.readOnly} onChange={onChangeText} />
        </div>;
}
export default Text;