import Item from "@component/view/Form/Item";
import React, { useContext } from "react";
import FormContext from "@component/core/Context/Form/FormContext";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import { Button } from "@fluentui/react-components";
import { AddFilled, DeleteRegular } from "@fluentui/react-icons";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import FormDefinitionContext from "@component/core/Context/FormDefinition/FormDefinitionContext";
import { joinPaths } from "@lib/form/path";
import unflatten from "@lib/form/unflatten";
const tag = "@component/view/ForOf";
export function ForOf({
  item,
  path = "",
  titleWidth,
  navItem
}) {
  const {
    navigation
  } = useContext(NavigationContext);
  const {
    form,
    mergeForm,
    removeByPath
  } = useContext(FormContext);
  const {
    formDefinition
  } = useContext(FormDefinitionContext);
  const p = joinPaths(path, item.attrib);
  const arr = unflatten(form, p) || [];
  if (item.attrib === "prilohy") {}
  const addItem = () => {
    const uids = (unflatten(form, joinPaths(path, item.attrib)) || []).map(i => i._key);
    const genUniqueUID = () => {
      let uid = uuidv4();
      if (uids.indexOf(uid) >= 0) {
        uid = uuidv4();
      }
      uids.push(uid);
      return uid;
    };
    let d: Record<string, any> = {};
    let buff: Array<any> = [{
      item,
      path
    }];
    while (buff.length > 0) {
      const buffItem = buff.shift();
      if (buffItem.item.type === "section") {
        buff = buffItem.item.items.map(item => ({
          item,
          required: !!buffItem.required,
          path: buffItem.path
        })).concat(buff);
      } else if (buffItem.item.type === "for-of") {
        let _p = joinPaths(buffItem.path, buffItem.item.attrib);
        if (_p !== joinPaths(path, item.attrib)) {
          continue;
        }
        let _np = joinPaths(_p, genUniqueUID());
        d[joinPaths(_np, "_index")] = (unflatten(form, _p) || []).length;
        buff = buffItem.item.item.map(i => ({
          item: i,
          path: _np,
          required: true,
          forOfPath: _p
        })).concat(buff);
      } else if (buffItem.path.startsWith(p)) {
        const p = joinPaths(buffItem.path, buffItem.item.attrib);
        if (typeof d[p] !== "undefined" && d[p] !== null) {
          continue;
        }
        if (typeof buffItem.item.defaultValue !== "undefined") {
          d[p] = buffItem.item.defaultValue;
          continue;
        }
        if (!buffItem.required) {
          continue;
        }
        if (buffItem.item.type === "text") {
          d[p] = "";
        } else if (buffItem.item.type === "number") {
          if (!!buffItem.item.autoNumbering && typeof buffItem.forOfPath !== "undefined") {
            const startWith = typeof buffItem.item.startWith === "number" ? buffItem.item.startWith : 1;
            d[p] = (unflatten(form, buffItem.forOfPath) || []).length + startWith;
            continue;
          }
          d[p] = null;
        }
        if (buffItem.item.type === "date") {
          d[p] = moment().toISOString();
        } else if (buffItem.item.type === "boolean") {
          d[p] = false;
        } else if (buffItem.item.type === "textarea") {
          d[p] = "";
        }
      }
    }
    mergeForm(d);
  };
  return <React.Fragment>
            <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      marginBottom: 20
    }}>
                {(arr || []).map(i => {
        const deleteItem = () => {
          removeByPath(i._path);
        };
        return <div key={i._path} style={{
          paddingTop: 20,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "stretch",
          borderBottom: "1px dotted #AAAAAA"
        }}>
                            <div style={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            marginRight: 10
          }}>
                                {item.item.map((itemdef, index) => <Item key={`${i._path};${itemdef.attrib || itemdef.key || index}`} item={itemdef} path={i._path} titleWidth={titleWidth} navItem={navItem} list={arr || []} itemData={i} />)}
                            </div>
                            {!navItem.data.readOnly && <Button style={{
            alignSelf: "stretch"
          }} icon={<DeleteRegular />} disabled={!!navItem.data.readOnly || !item.remove || arr.length < 2 && !!item.atLeastOne} onClick={deleteItem}>
                                </Button>}
                        </div>;
      })}
                {!!item.add && !navItem.data.readOnly && <Button style={{
        alignSelf: "center",
        marginTop: 10
      }} icon={<AddFilled />} onClick={addItem}>
                        {item.addText || ""}
                    </Button>}
            </div>
        </React.Fragment>;
}
export default ForOf;