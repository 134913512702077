import { flatten } from "@lib/form/flatten";
import unflatten from "@lib/form/unflatten";
import merge from "@lib/merge";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import { joinPaths } from "@lib/form/path";
export const normalize = (data, defs) => {
  const uids = [];
  const genUniqueUID = () => {
    let uid = uuidv4();
    if (uids.indexOf(uid) >= 0) {
      uid = uuidv4();
    }
    uids.push(uid);
    return uid;
  };
  let d = {
    ...data
  };
  const def = defs[data.typ];
  let buff = def.sections.map(item => ({
    item,
    path: ""
  }));
  while (buff.length > 0) {
    const buffItem = buff.shift();
    if (buffItem.item.type === "section") {
      buff = buffItem.item.items.map(item => ({
        item,
        required: !!buffItem.required,
        path: buffItem.path
      })).concat(buff);
    } else if (buffItem.item.type === "for-of" && buffItem.item.atLeastOne) {
      let p = joinPaths(buffItem.path, buffItem.item.attrib) + ".";
      const arrKeys = Object.keys(data).filter(key => key.startsWith(p));
      if (arrKeys.length === 0) {
        const arr = [];
        const np = `${p}${genUniqueUID()}`;
        buff = buffItem.item.item.map(i => ({
          item: i,
          path: np,
          required: true,
          forOfPath: p
        })).concat(buff);
      }
    } else {
      const p = joinPaths(buffItem.path, buffItem.item.attrib);
      if (typeof d[p] !== "undefined" && d[p] !== null) {
        continue;
      }
      if (typeof buffItem.item.defaultValue !== "undefined") {
        d[p] = buffItem.item.defaultValue;
        continue;
      }
      if (!buffItem.required) {
        continue;
      }
      if (buffItem.item.type === "text") {
        d[p] = "";
      } else if (buffItem.item.type === "number") {
        if (!!buffItem.item.autoNumbering && typeof buffItem.forOfPath !== "undefined") {
          d[p] = 1;
          continue;
        }
        d[p] = null;
      }
      if (buffItem.item.type === "date") {
        d[p] = moment().toISOString();
      } else if (buffItem.item.type === "boolean") {
        d[p] = false;
      } else if (buffItem.item.type === "textarea") {
        d[p] = "";
      }
    }
  }
  return d;
};
export default normalize;