import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import { joinPaths } from './path';
const reDigits = new RegExp("^\\d+$");
export const unflatten = (data, path = "", _underscore = true) => {
  const obj = {};
  let buff: Array<Record<string, any>> = Object.keys(data).filter(key => path ? key.startsWith(`${path}.`) || key === path : true).map(key => ({
    path: key,
    absolutePath: key,
    curPath: "",
    obj
  }));
  console.log(buff);
  while (buff.length > 0) {
    const buffItem = buff.shift();
    if (!buffItem) {
      continue;
    }
    const pathItems = buffItem.path.split(".");
    const key = pathItems[0];
    if (key.startsWith("_")) {
      continue;
    }
    if (pathItems.length > 1) {
      let biObj: any = null;
      if (uuidValidate(pathItems[1]) && uuidVersion(pathItems[1]) === 4) {
        if (buffItem.obj instanceof Array) {
          biObj = [];
          buffItem.obj.push(biObj);
        } else {
          biObj = [];
          buffItem.obj[key] = biObj;
        }
      } else if (buffItem.obj instanceof Array) {
        const p = joinPaths(buffItem.curPath, key);
        const indexPath = joinPaths(p, "_index");
        biObj = {
          _index: typeof data[indexPath] === "number" ? data[indexPath] : buffItem.obj.length,
          _key: key,
          _path: p
        };
        if (!_underscore) {
          delete biObj._path;
          delete biObj._index;
          delete biObj._key;
        }
        buffItem.obj.push(biObj);
        if (buffItem.arr) {
          buffItem.obj.sort((a, b) => a._index - b._index);
        }
      } else {
        const p = joinPaths(buffItem.curPath, key);
        biObj = {
          _key: key,
          _path: p
        };
        if (!_underscore) {
          delete biObj._path;
          delete biObj._index;
          delete biObj._key;
        }
        buffItem.obj[key] = biObj;
      }
      buff.unshift(buffItem);
      buff = buff.map(item => {
        if (item.path.startsWith(`${key}.`)) {
          item.obj = biObj;
          item.arr = buffItem.obj instanceof Array ? buffItem.obj : null;
          item.curPath += (item.curPath ? "." : "") + item.path.slice(0, key.length);
          item.path = item.path.slice(key.length + 1);
        }
        return item;
      });
    } else {
      if (buffItem.obj instanceof Array) {
        buffItem.obj.push(data[buffItem.absolutePath]);
      } else {
        buffItem.obj[key] = data[buffItem.absolutePath];
      }
    }
  }
  if (path) {
    let ret = obj;
    const pathItemKeys = path.split(".");
    for (const pathItemKey of pathItemKeys) {
      if (ret instanceof Array && uuidValidate(pathItemKey) && uuidVersion(pathItemKey) === 4) {
        if (!ret) {
          return undefined;
        }
        ret = ret[0];
      } else {
        if (!ret) {
          return undefined;
        }
        ret = ret[pathItemKey];
      }
    }
    return (ret as any);
  }
  return (obj as any);
};
export default unflatten;